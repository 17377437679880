import { Inject, Injectable } from '@angular/core';
import { SiteId } from '../../site-detail/models/Site';
import { VideoCallGateway } from './VideoCallGateway';

@Injectable()
export class StopVideoCallUseCase {
    constructor(@Inject('VideoCallGateway') private videoCallGateway: VideoCallGateway) {}

    exec(siteId: SiteId): void {
        this.videoCallGateway.stopVideoCall(siteId).subscribe();
    }
}
