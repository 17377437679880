import { SiteId } from '../../site-detail/models/Site';

export type VideoCallStatus = (typeof VIDEO_CALL_STATUS)[keyof typeof VIDEO_CALL_STATUS];

export const VIDEO_CALL_STATUS = {
    IN_PROGRESS: 'in-progress',
    AVAILABLE: 'available',
} as const;

export interface VideoCall {
    url: string;
    token: string;
    guestUrl: string;
}

export interface VideoCallModalData {
    siteId: SiteId;
    url: string;
    guestUrl: string;
}

export type ParticipantType = (typeof PARTICIPANT_TYPE)[keyof typeof PARTICIPANT_TYPE];

export const PARTICIPANT_TYPE = {
    OWNER: 'owner',
    GUEST: 'guest',
} as const;

export type Participant = {
    videoStream: MediaStream | undefined;
    audioStream: MediaStream | undefined;
    userName: string;
    type: ParticipantType;
    id: string;
};

export type ParticipantTracks = {
    video: MediaStreamTrack;
    audio: MediaStreamTrack;
    kind: string;
};

export type VideoParticipants = { [key in ParticipantType]: Participant };
