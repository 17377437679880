import { Injectable } from '@angular/core';
import DailyIframe, {
    DailyCall,
    DailyEventObjectParticipant,
    DailyEventObjectParticipantLeft,
    DailyEventObjectParticipants,
    DailyEventObjectTrack,
    DailyParticipant,
} from '@daily-co/daily-js';
import { Observable } from 'rxjs';
import { Participant, PARTICIPANT_TYPE, VideoParticipants } from '../models/video-conference';
import { VideoCallParticipantManager } from './VideoCallParticipantManager';
import { VideoCallProvider } from './VideoCallProvider';

@Injectable()
export class DailyVideoCallProvider implements VideoCallProvider {
    private callObject: DailyCall | undefined;

    constructor(private videoCallParticipantManager: VideoCallParticipantManager) {}

    getParticipants(): Observable<VideoParticipants> {
        return this.videoCallParticipantManager.getParticipants();
    }

    initializeCall(dailyRoomUrl: string, userName: string = 'Installer'): void {
        this.callObject = DailyIframe.getCallInstance() || DailyIframe.createCallObject();

        this.callObject
            .on('joined-meeting', this.handleJoinedMeeting)
            .on('participant-joined', this.handleParticipantJoined)
            .on('track-started', this.handleTrackStarted)
            .on('track-stopped', this.handleTrackStopped)
            .on('participant-left', this.handleParticipantLeft)
            .on('left-meeting', this.handleLeftMeeting);

        this.callObject.join({
            userName,
            url: dailyRoomUrl.split('?t=')[0],
            token: dailyRoomUrl.split('?t=')[1],
        });
    }

    leaveCall(): void {
        if (this.callObject) {
            this.callObject.leave();
            this.callObject.destroy();
            this.videoCallParticipantManager.resetParticipants();
        }
    }

    destroy(): void {
        this.videoCallParticipantManager.resetParticipants();
        this.callObject
            ?.off('joined-meeting', this.handleJoinedMeeting)
            .off('participant-joined', this.handleParticipantJoined)
            .off('track-started', this.handleTrackStarted)
            .off('track-stopped', this.handleTrackStopped)
            .off('participant-left', this.handleParticipantLeft)
            .off('left-meeting', this.handleLeftMeeting);
    }

    private handleLeftMeeting = (): void => {
        this.callObject.destroy();
    };

    private handleJoinedMeeting = (e: DailyEventObjectParticipants): void => {
        const currentParticipant = this.getParticipant(e.participants.local);
        if (currentParticipant) {
            return;
        }

        this.videoCallParticipantManager.addParticipant(this.formatParticipant(e.participants.local));
    };

    private handleParticipantJoined = (e: DailyEventObjectParticipant): void => {
        const currentParticipant = this.getParticipant(e.participant);
        if (currentParticipant) {
            return;
        }

        this.videoCallParticipantManager.addParticipant(this.formatParticipant(e.participant));
    };

    private handleTrackStarted = (e: DailyEventObjectTrack): void => {
        if (!e?.participant) {
            return;
        }

        const participant = this.getParticipant(e.participant);
        const tracks = this.getParticipantTracks(e.participant, e.track);

        this.videoCallParticipantManager.updateTrackForParticipant(participant, tracks, true);
    };

    private handleTrackStopped = (e: DailyEventObjectTrack): void => {
        if (!e?.participant) {
            return;
        }

        const participant = this.getParticipant(e.participant);
        const tracks = this.getParticipantTracks(e.participant, e.track);

        this.videoCallParticipantManager.updateTrackForParticipant(participant, tracks, false);
    };

    private handleParticipantLeft = (e: DailyEventObjectParticipantLeft): void => {
        if (!e?.participant) {
            return;
        }
        const participant = this.getParticipant(e.participant);
        this.videoCallParticipantManager.removeParticipant(participant);
    };

    private formatParticipant(participant: DailyParticipant) {
        const formattedParticipant: Participant = {
            videoStream: undefined,
            audioStream: undefined,
            userName: participant.user_name,
            type: this.getParticipantType(participant),
            id: participant.session_id,
        };
        return formattedParticipant;
    }

    private getParticipantTracks(participant: DailyParticipant, track: MediaStreamTrack) {
        return {
            video: participant.tracks.video.persistentTrack,
            audio: participant.tracks.audio.persistentTrack,
            kind: track.kind,
        };
    }

    private getParticipantType(participant: DailyParticipant) {
        return participant.owner ? PARTICIPANT_TYPE.OWNER : PARTICIPANT_TYPE.GUEST;
    }

    private getParticipant(participant: DailyParticipant) {
        let currentParticipant: Participant;
        const participantType = this.getParticipantType(participant);
        this.videoCallParticipantManager
            .getParticipants()
            .subscribe((participants) => (currentParticipant = participants[participantType]));

        return currentParticipant;
    }
}
