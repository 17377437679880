import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Participant, PARTICIPANT_TYPE, ParticipantTracks, VideoParticipants } from '../models/video-conference';

@Injectable()
export class VideoCallParticipantManager {
    private videoParticipants: BehaviorSubject<VideoParticipants>;

    constructor() {
        this.videoParticipants = new BehaviorSubject<VideoParticipants>({
            [PARTICIPANT_TYPE.OWNER]: null,
            [PARTICIPANT_TYPE.GUEST]: null,
        });
    }

    getParticipants() {
        return this.videoParticipants.asObservable();
    }

    addParticipant(participant: Participant): void {
        this.updateParticipants(participant);
    }

    updateTrackForParticipant(participant: Participant | null, tracks: ParticipantTracks, isStarting: boolean): void {
        if (!participant) {
            return;
        }

        const streamKey = tracks.kind === 'video' ? 'videoStream' : 'audioStream';
        const stream = participant[streamKey] ?? new MediaStream();

        const track = tracks[tracks.kind];
        if (track) {
            if (isStarting) {
                stream.addTrack(track);
            } else {
                stream.removeTrack(track);
            }

            this.updateParticipants({
                ...participant,
                [streamKey]: stream,
            });
        }
    }

    removeParticipant(participant: Participant): void {
        if (!participant) {
            return;
        }

        participant.videoStream?.getTracks().forEach((track) => track.stop());
        participant.audioStream?.getTracks().forEach((track) => track.stop());

        const updatedParticipants = { ...this.videoParticipants.value };
        delete updatedParticipants[participant.type];

        this.videoParticipants.next(updatedParticipants);
    }

    private updateParticipants(updatedParticipant: Participant | null): void {
        const participants = { ...this.videoParticipants.value };
        participants[updatedParticipant.type] = updatedParticipant;
        this.videoParticipants.next(participants);
    }

    resetParticipants(): void {
        this.videoParticipants.next({
            [PARTICIPANT_TYPE.OWNER]: null,
            [PARTICIPANT_TYPE.GUEST]: null,
        });
    }
}
